
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { AttrDetail } from '@/types/product'

@Component({
  name: 'AttrAdd'
})
export default class extends Vue {
  private title = '新增属性'
  private info: AttrDetail = {
    attributeName: '',
    sdescribe: ''
  }

  private rules = {
    attributeName: [
      { required: true, message: '请输入属性名称', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false

  get attributeId () {
    return this.$route.params.id || ''
  }

  created () {
    if (this.attributeId) {
      this.title = '编辑属性'
      this.getDetail()
    }
  }

  // 属性详情
  getDetail () {
    this.$axios.get(this.$apis.product.selectProductAttributeByAttributeId, {
      attributeId: this.attributeId
    }).then((res) => {
      this.info = res
    })
  }

  // 提交
  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.addAttr()
      }
    })
  }

  // 用户详情
  addAttr () {
    this.submitShow = true
    const url = this.attributeId
      ? this.$apis.product.updateProductAttribute
      : this.$apis.product.insertProductAttribute
    this.$axios.post(url, this.info).then(() => {
      this.submitShow = false
      this.$message.success('保存成功')
      this.$router.push({ path: '/productList/baseSet/attr' })
    }).finally(() => {
      this.submitShow = false
    })
  }
}
